import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
  useAmIMemberActions,
  useAmIMemberStore,
} from '~/model/member/am-i-member/use-am-i-member-store';

export type InternalRedirectProps = {
  redirectUrl: string;
};

export const useIsNotMemberRedirect = ({ redirectUrl }: InternalRedirectProps) => {
  const { amIMember } = useAmIMemberStore();
  const { getAmIMember } = useAmIMemberActions();
  const router = useRouter();

  useEffect(() => {
    if (amIMember.updated === 0) {
      getAmIMember();
    }
  }, [amIMember.updated, getAmIMember]);

  const [redirect, setRedirect] = useState<boolean>(true);

  useEffect(() => {
    if (amIMember.data && amIMember.data.isMember === false) {
      router.push(redirectUrl);
      setRedirect(true);
    } else if (amIMember.data && amIMember.data.isMember === true) {
      setRedirect(false);
    }
  }, [amIMember.data, redirectUrl, router]);

  return redirect;
};
