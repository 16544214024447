
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/klubb-hjartat/mina-sidor",
      function () {
        return require("private-next-pages/klubb-hjartat/mina-sidor/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/klubb-hjartat/mina-sidor"])
      });
    }
  